@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .glitch-text {
    text-shadow: 
      0 0 5px #00ff00,
      0 0 10px #00ff00,
      0 0 15px #00ff00;
    animation: glitch 2s infinite;
  }

  .typing-animation {
    border-right: 2px solid #00ff00;
    animation: typing 3s steps(40, end), blink-caret .75s step-end infinite;
  }

  .glass-card {
    @apply bg-aero-glass backdrop-blur-sm border border-aero-border;
    box-shadow: 
      0 8px 32px 0 rgba(31, 38, 135, 0.37),
      inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
  
  .glass-button {
    @apply bg-gradient-to-b from-aero-blue/30 to-aero-blue/10 
           backdrop-blur-sm border border-aero-border
           hover:from-aero-blue/40 hover:to-aero-blue/20
           active:from-aero-blue/20 active:to-aero-blue/5
           text-aero-white font-light;
    text-shadow: 0 0 10px rgba(0, 122, 255, 0.5);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.1),
      0 0 10px rgba(0, 122, 255, 0.2);
  }
}

@keyframes glitch {
  0% {
    text-shadow: 0 0 5px #00ff00;
  }
  50% {
    text-shadow: 0 0 10px #00ff00, 0 0 15px #00ff00;
  }
  100% {
    text-shadow: 0 0 5px #00ff00;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #00ff00 }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #004080 0%, #0066cc 100%);
  background-attachment: fixed;
}

.aero-glow {
  box-shadow: 
    0 0 20px rgba(255, 255, 255, 0.1),
    inset 0 0 20px rgba(255, 255, 255, 0.1);
}